import React from 'react';
import styled, { css } from 'styled-components';
import ABAnalytics_bg from '../../assets/images/ABAnalytics_bg.jpg';
import ABAnalytics_bg_mobile from '../../assets/images/ABAnalytics_bg_mobile.jpg';
import ABAnalyticsGraph from '../../assets/images/analytics_diagram.svg';
import ScrollLink from '../../components/atoms/ScrollLink';
import {
  TextLarge,
  TextMedium,
  Title,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import useMaxWidthBreakpoint from '../../hooks/useMaxWidthBreakpoint';
import { forScreenAtMost680pxWide } from '../../styles/mediaQueries';
import ScrollIcon from '../../assets/images/Scroll.inline.svg';

const IntroSection = (): JSX.Element => {
  const mobileLayout = useMaxWidthBreakpoint(680);
  const tabletLayout = useMaxWidthBreakpoint(1024);

  return (
    <Wrapper mobileLayout={mobileLayout}>
      <MainContentColumnWrapper>
        <IntroSectionWrapper>
          <TextsBlock>
            <Upheader $color="delicateAccented">Products</Upheader>
            <SectionTitle>Antibody Analytics</SectionTitle>
            <SectionText>
              <SectionPunchline>
                Accelerate your therapeutics pipeline with Antibody Analytics
              </SectionPunchline>{' '}
              Based on the comprehensive Antibody Knowledge Graph, we develop
              analytics solutions that connect the dots and provide insights
              into antibody developability, immunogenicity binding, and other
              features. Researchers use our solutions to quickly discover the
              advantages of individual candidate antibodies, stratify outputs of
              Next-Generation Sequencing (NGS) or phage display, and - most
              importantly - enhance their therapeutics pipelines thanks to
              data-driven insights into antibodies.
            </SectionText>
            {!tabletLayout && (
              <ScrollLink $color="delicate" to="#product-content">
                <ScrollIcon />
                Learn more
              </ScrollLink>
            )}
          </TextsBlock>
          <ImageDecoration height={612} src={ABAnalyticsGraph} width={430} />
        </IntroSectionWrapper>
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div<{ mobileLayout: boolean }>`
  ${({ mobileLayout }) =>
    mobileLayout
      ? css`
          background-image: url('${ABAnalytics_bg_mobile}');
        `
      : css`
          background-image: url('${ABAnalytics_bg}');
          height: 1000px;
        `}
  background-size: cover;
  background-blend-mode: overlay;
  background-position: top center;
  position: relative;

  :before {
    content: '';
    position: absolute;
    width: 35%;
    background-color: ${({ theme }) => theme.colors.backgrounds.secondary};
    height: 10px;
    bottom: 0px;
  }

  ${forScreenAtMost680pxWide(css`
    :before {
      width: 50%;
    }
  `)}
`;

const SectionTitle = styled(Title).attrs({ $color: 'primaryAccented' })`
  max-width: 455px;
  margin: 7px 0 25px;
`;

const SectionPunchline = styled(TextLarge).attrs({ $color: 'primaryAccented' })`
  max-width: 455px;
  margin: 7px 0 25px;
`;

const SectionText = styled(TextMedium).attrs({ $color: 'delicate' })`
  max-width: 424px;
  margin: 0 0 32px;
`;

const TextsBlock = styled.div`
  padding: 250px 0 20px 0;
`;

const ImageDecoration = styled.img`
  max-width: 100%;
  width: auto;
  height: auto;
  z-index: 1;
  padding-top: 200px;

  ${forScreenAtMost680pxWide(css`
    padding: 0;
    margin-bottom: 100px;
  `)}
`;

const IntroSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  gap: 50px;

  ${forScreenAtMost680pxWide(css`
    grid-template-columns: 1fr;
  `)}
`;

export default IntroSection;
