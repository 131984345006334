import React from 'react';
import styled, { css } from 'styled-components';
import { AccentedLinkBig } from '../../components/atoms/AccentedLink';
import { Header, Upheader } from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import {
  forScreenAtMost680pxWide,
  forScreenAtMost800pxWide,
} from '../../styles/mediaQueries';

interface NextProductSectionProps {
  href: string;
  title: string;
}

const NextProductSection = ({
  href,
  title,
}: NextProductSectionProps): JSX.Element => (
  <Wrapper>
    <MainContentColumnWrapper>
      <NextProductBoxWrapper>
        <NextProductLink to={href}>
          <Upheader $color="delicateStronger">NEXT PRODUCT</Upheader>
          <AccentedLinkTitle>{title}</AccentedLinkTitle>
        </NextProductLink>
      </NextProductBoxWrapper>
    </MainContentColumnWrapper>
  </Wrapper>
);

const Wrapper = styled.div`
  overflow: hidden;
  padding: 200px 0 167px 0;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};

  ${forScreenAtMost800pxWide(
    css`
      padding-top: 125px;
      padding-bottom: 307px;
    `,
  )}
`;

const NextProductBoxWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const NextProductLink = styled(AccentedLinkBig).attrs({
  $accentColor: 'secondaryAccented',
  $color: 'secondary',
  $focus: 'tertiaryStronger',
  $focusBg: 'focusSecondary',
})`
  width: 37%;
  padding: 33px 70px 33px 42px;
  background: ${({ theme }) => theme.colors.backgrounds.secondaryAccented};
  transition: background 0.2s;

  :after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 42px;
    margin: 0;
  }

  :hover {
    background: ${({ theme }) => theme.colors.backgrounds.secondaryPair};
  }

  ${forScreenAtMost680pxWide(
    css`
      width: 100%;
      margin-bottom: 43px;
    `,
  )}
`;

const AccentedLinkTitle = styled(Header).attrs({ $color: 'secondary' })`
  margin: 3px 0 0 0;
  font-size: ${({ theme }) => theme.fontSizes.nextProductBox.linkTitle};
  line-height: ${({ theme }) => theme.lineHeights.nextProductBox.linkTitle};
`;

export default NextProductSection;
