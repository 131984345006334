import React from 'react';
import routes from '../routes';
import Layout from '../components/layout/Layout';
import IntroSection from '../pages-components/antibody-analytics/IntroSection';
import WhatWeDoSection from '../pages-components/antibody-analytics/WhatWeDoSection';
import NextProductSection from '../pages-components/shared/NextProductSection';
import { FooterBoxContent } from '../components/layout/Footer';

const ProductsPage = (): JSX.Element => {
  return (
    <Layout
      description="Explore our antibody analytics solutions that provide data-driven insights into antibody developability, immunogenicity binding, and other features."
      footerBoxContent={FooterBoxContent.ContactInvitation}
      title="Antibody Analytics | Antibody Optimization"
    >
      <IntroSection />
      <WhatWeDoSection />
      <NextProductSection
        href={routes.antibodyKnowledgeGraph}
        title="Antibody Database"
      />
    </Layout>
  );
};

export default ProductsPage;
