import React from 'react';
import styled, { css } from 'styled-components';
import circlesDecorator from '../../assets/images/circlesDecorator.svg';
import immunogencityImg from '../../assets/images/immunogencity.png';
import immunogencityDecorator from '../../assets/images/immunogencityDecorator.svg';
import bsaImage from '../../assets/images/bsa-image.png';
import structureModellingImg from '../../assets/images/structureModelling.png';
import structureModellingDecorator from '../../assets/images/structureModellingDecorator.svg';
import { UpperCaseLinkMedium } from '../../components/atoms/Link';
import {
  Header1,
  TextMedium,
  Upheader,
} from '../../components/atoms/Typography/Typography';
import { MainContentColumnWrapper } from '../../components/layout/Layout';
import {
  forScreenAtMost1024pxWide,
  forScreenAtMost680pxWide,
} from '../../styles/mediaQueries';
import ClusteringIcon from '../../assets/images/tree.svg';
import Structure3DIcon from '../../assets/images/structure_3d_icon.svg';
import BatchSequenceIcon from '../../assets/images/batchSeqIcon.svg';
import SequenceEngineeringIcon from '../../assets/images/seIcon.svg';

const whatWeDoContent = [
  {
    heading: 'Sequence Engineering',
    text: 'Sequence Engineering module enables molecule developability optimization such as liability removal or humanization via combining two types of computational scores: Indicating possible issues with the molecule and Showing mutational choices to mitigate issues.',
    image: immunogencityImg,
    decorator: immunogencityDecorator,
    icons: [SequenceEngineeringIcon],
  },
  {
    heading: 'Batch Sequence Annotation with Clustering',
    text: `Batch Sequence Annotation allows users to group sequences & annotate their liabilities. Users upload a set (batch) of antibody sequences in a well-adopted format (e.g., .fasta) and filter it by computational/machine learning attributes.`,
    image: bsaImage,
    decorator: circlesDecorator,
    icons: [BatchSequenceIcon, ClusteringIcon],
  },
  {
    heading: 'Structure Modeling',
    text: `NaturalAntibody Structure Modeling allows therapeutic antibody lead optimization in the structural context. It offers information on what could be wrong with the molecule, where it could be modified, but offering a 3D context for the analysis.`,
    image: structureModellingImg,
    decorator: structureModellingDecorator,
    icons: [Structure3DIcon],
  },
];

const WhatWeDoSection = (): JSX.Element => {
  return (
    <Wrapper id="product-content">
      <MainContentColumnWrapper>
        <StyledUpheader $color="delicateAccented">What We Do</StyledUpheader>
        {whatWeDoContent.map((content, index) => (
          <SingleItemWrapper key={index}>
            <ImageWrapper decorator={content.decorator}>
              <img src={content.image} />
            </ImageWrapper>
            <SingleItemContentWrapper>
              <IconsWrapper>
                {content.icons.map((icon, index) => (
                  <IconHeader key={`${icon}_${index}`}>
                    <img src={icon} />
                  </IconHeader>
                ))}
              </IconsWrapper>
              <StyledHeader>{content.heading}</StyledHeader>
              <StyledTextMedium>{content.text}</StyledTextMedium>
            </SingleItemContentWrapper>
          </SingleItemWrapper>
        ))}
      </MainContentColumnWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  overflow: hidden;
  padding: 104px 0 0 0;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};
`;

const ImageWrapper = styled('div')<{ decorator: string }>`
  position: relative;
  > img {
    position: relative;
    max-width: 100%;
    z-index: ${({ theme }) => theme.layers.base + 1};
  }

  :after {
    position: absolute;
    content: ${({ decorator }) => css`url(${decorator});`};
  }

  ${forScreenAtMost1024pxWide(css`
    margin-bottom: 24px;
  `)}
`;

const SingleItemWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 170px;

  &:last-of-type {
    padding-bottom: 0;
  }

  &:nth-of-type(even) {
    flex-direction: row-reverse;

    ${ImageWrapper} {
      text-align: right;
    }
  }

  &:nth-of-type(1) {
    ${ImageWrapper} {
      :after {
        top: -288px;
        left: -185px;
      }
    }
  }
  &:nth-of-type(2) {
    ${ImageWrapper} {
      :after {
        top: -40%;
        left: -63px;
        transform: translateY(-50%);
      }
    }
  }
  &:nth-of-type(3) {
    ${ImageWrapper} {
      :after {
        top: -50%;
        left: -40%;
      }
    }
  }

  ${forScreenAtMost1024pxWide(css`
    flex-direction: column !important;
    padding-bottom: 100px;

    &:nth-of-type(1) {
      ${ImageWrapper} {
        :after {
          top: -188px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &:nth-of-type(5) {
      ${ImageWrapper} {
        :after {
          transform: rotate(136deg) scale(0.7);
        }
      }
    }
  `)}

  ${forScreenAtMost680pxWide(css`
    &:nth-of-type(1) {
      ${ImageWrapper} {
        :after {
          transform: translateX(-50%) scale(0.7);
        }
      }
    }
    &:nth-of-type(2) {
      ${ImageWrapper} {
        :after {
          display: none;
        }
      }
    }
    &:nth-of-type(3),
    &:nth-of-type(4),
    &:nth-of-type(5) {
      ${ImageWrapper} {
        img {
          max-width: 250px;
        }

        :after {
          display: none;
        }
      }
    }
  `)}
`;

const SingleItemContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 50%;
  max-width: 454px;
  z-index: ${({ theme }) => theme.layers.base + 1};

  ${forScreenAtMost1024pxWide(css`
    width: 100%;
    max-width: unset;
  `)}
`;

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
const Pill = styled(UpperCaseLinkMedium).attrs({
  as: 'span',
  $color: 'delicate',
})`
  display: block;
  padding: 7px 16px;
  font-weight: 700;
  background: ${({ theme }) => theme.colors.backgrounds.secondaryPair};
  border-radius: 35px;
  margin: 0 0 10px 0;
  cursor: initial;
`;

const StyledUpheader = styled(Upheader).attrs({ $color: 'delicateAccented' })`
  text-align: center;
  margin-bottom: 40px;

  ${forScreenAtMost1024pxWide(css`
    margin-bottom: 129px;
  `)}
`;

const StyledHeader = styled(Header1).attrs({ as: 'h2', $color: 'secondary' })`
  width: 100%;
  margin: 0 0 28px 0;
`;

const StyledTextMedium = styled(TextMedium).attrs({
  $color: 'delicateStronger',
})`
  margin: 0;
  padding-left: 30px;

  strong {
    color: ${({ theme }) => theme.colors.backgrounds.primaryAccented};
  }

  ${forScreenAtMost1024pxWide(css`
    padding-left: 0px;
  `)}
`;

const IconHeader = styled.div``;

const IconsWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
`;

export default WhatWeDoSection;
